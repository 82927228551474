@import url("https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Text:ital,wght@0,300..700;1,300..700&display=swap");
@font-face {
  font-family: "ClashGrotesk-Extralight";
  src: url("./fonts/ClashGrotesk-Extralight.woff2") format("woff2"),
    url("./fonts/ClashGrotesk-Extralight.woff") format("woff"),
    url("./fonts/ClashGrotesk-Extralight.ttf") format("truetype");
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "ClashGrotesk-Light";
  src: url("./fonts/ClashGrotesk-Light.woff2") format("woff2"),
    url("./fonts/ClashGrotesk-Light.woff") format("woff"),
    url("./fonts/ClashGrotesk-Light.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "ClashGrotesk-Regular";
  src: url("./fonts/ClashGrotesk-Regular.woff2") format("woff2"),
    url("./fonts/ClashGrotesk-Regular.woff") format("woff"),
    url("./fonts/ClashGrotesk-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "ClashGrotesk-Medium";
  src: url("./fonts/ClashGrotesk-Medium.woff2") format("woff2"),
    url("./fonts/ClashGrotesk-Medium.woff") format("woff"),
    url("./fonts/ClashGrotesk-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "ClashGrotesk-Semibold";
  src: url("./fonts/ClashGrotesk-Semibold.woff2") format("woff2"),
    url("./fonts/ClashGrotesk-Semibold.woff") format("woff"),
    url("./fonts/ClashGrotesk-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "ClashGrotesk-Bold";
  src: url("./fonts/ClashGrotesk-Bold.woff2") format("woff2"),
    url("./fonts/ClashGrotesk-Bold.woff") format("woff"),
    url("./fonts/ClashGrotesk-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Figtree", sans-serif;
  scroll-behavior: smooth;
}
.f-nunito {
  font-family: "Nunito", serif;
}
:root {
  --font-size-small: 12px;
  --font-size-medium: 16px;
  --font-size-mediumL: 18px;
  --font-size-large: 24px;
  --font-size-lx: 32px;
  --font-size-exlarge: 60px;
  --font1: "Figtree", sans-serif;
  --fontInter: "Inter", sans-serif;
  --primaryColor: #a7fc00;
  --white: #ffffff;
  --black: #000000;
  --transP1: #683fea17;
  --transP2: #683fea12;
  --mTop1: 24px;
  --mTop2: 20px;
  --mTop3: 56px;
  --grayL: #d9dad9;
  --grayL2: #b3b4b3;
  --darkGray: #838383;
  --deepDark: #141414;
  --dark: #1d1d1d;
}
/* Targeting the entire scrollbar */
::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
  height: 10px; /* Height of the scrollbar for horizontal scrolling */
}

/* Styling the track (background of the scrollbar) */
::-webkit-scrollbar-track {
  background-color: black;
}

/* Styling the thumb (the draggable part of the scrollbar) */
::-webkit-scrollbar-thumb {
  background-color: #555;
  border-radius: 10px;
}

/* When hovering over the thumb */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Darker gray when hovered */
}

::-webkit-scrollbar-button {
  background: #ccc;
  display: none;
  height: 10px;
  width: 10px;
}

/* Optional: styling for the corner between the vertical and horizontal scrollbars */
::-webkit-scrollbar-corner {
  background: #ddd;
}
button {
  outline: none;
  -webkit-tap-highlight-color: transparent;
}
div {
  -webkit-tap-highlight-color: transparent;
}
p {
  -webkit-tap-highlight-color: transparent;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  -webkit-tap-highlight-color: transparent;
}
.body {
  width: 100%;
  padding-left: 8.5%;
  padding-right: 8.5%;
  min-height: 100vh;
  background-color: #000000;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-image: url("../assets/bgImg.webp");
  background-size: cover;
}

/* Navbar*/
.navbar {
  width: 0;
  overflow: hidden;
  background-color: #0d0e0da1;
  color: white;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 43px;
  border-radius: 30px;
  height: 78px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: width 1s ease-in-out;
  align-self: center;
  border: 1px solid #ffffff0a;
}
.navbar.expanded {
  width: 100%;
}
.navbar-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: 1;
}
.logo {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.8s ease, transform 0.8s ease;
}
.tbsLogo {
  width: 88px;
  height: 29px;
}
.logo.visible {
  opacity: 1;
  transform: translateY(0);
}
.nav-links {
  list-style: none;
  display: flex;
  gap: 32px;
  margin: 0;
  padding: 0;
}
.nav-links1 {
  list-style: none;
  display: flex;
  gap: 16px;
  margin: 0;
  padding: 0;
}
.nav-item {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.nav-item p {
  font-size: var(--font-size-medium);
  font-weight: 400;
  font-family: "Figtree", sans-serif;
  font-style: normal;
  margin-left: 12px;
}
.nav-item.active p {
  color: var(--primaryColor);
}
.nav-item.visible {
  opacity: 1;
  transform: translateY(0);
}
.lgLink1 {
  box-sizing: border-box;
  padding: 14px;
  font-size: 14px;
  background-color: var(--white);
  color: var(--black);
  font-weight: 500;
  border-radius: 100px;
  font-family: var(--fontInter);
}
.lgLink2 {
  box-sizing: border-box;
  padding: 14px;
  font-size: 14px;
  background-color: var(--primaryColor);
  color: var(--black);
  font-weight: 500;
  border-radius: 100px;
  font-family: var(--fontInter);
}
.textCard {
  margin-top: 88px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 12px;
  padding-right: 12px;
  background-color: #683fea17;
  border: 1px solid #683fea12;
  border-radius: 22px;
}
.textCard p {
  font-weight: 400;
  font-size: var(--font-size-small);
  color: var(--white);
  font-family: var(--font1);
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  opacity: 0;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
@keyframes fadeInTop {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-top {
  opacity: 0;
  animation-name: fadeInTop;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
.bannerText {
  font-size: var(--font-size-exlarge);
  font-weight: 500;
  color: var(--white);
  line-height: 72px;
  margin-top: 24px;
  letter-spacing: -1;
  font-family: var(--font1);
  text-align: center;
}
.typer {
  font-size: var(--font-size-exlarge);
  font-weight: 500;
  color: var(--white);
  margin-top: 18px;
  height: 60px;
  letter-spacing: -1;
  font-family: var(--font1);
  text-align: center;
}
@keyframes blink {
  50% {
    background-color: transparent;
  }
}
.desTextCont {
  align-self: center;
  width: 100%;
  /* background-color: red; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 26px;
}
.desTextContInner {
  width: 599px;
}
.desText {
  font-size: var(--font-size-mediumL);
  color: var(--grayL);
  line-height: 32px;
  font-family: var(--font1);
  text-align: center;
}
.primaryBtn {
  padding-top: 16.5px;
  padding-bottom: 16.5px;
  padding-left: 42.5px;
  padding-right: 42.5px;
  background-color: var(--primaryColor);
  border-radius: 100px;
  cursor: pointer;
}
.primaryBtn:hover {
  background-color: #b9fd33;
}
.primaryBtn p {
  font-size: var(--font-size-medium);
  font-family: var(--fontInter);
  font-weight: 500;
}
.btnWrap {
  margin-top: 30px;
}
.imgCont {
  width: 100%;
  margin-top: 42px;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bannerImg {
  width: 70vw;
  opacity: 0; /* Start hidden */
  animation: fadeIn1 1s forwards;
}
/* Basic Fade In Animation */
@keyframes fadeIn1 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fade-enter {
  animation: fadeIn1 1s forwards;
}

.fade-exit {
  animation: fadeOut 1s forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* what is tokenization screen */
.tkbody {
  width: 100%;
  padding-left: 8.5%;
  padding-right: 8.5%;
  min-height: 100vh;
  background-color: #000000;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.bt {
  margin-top: 80px;
}
.list-item-cont {
  /* width: 70%; */
  column-gap: 24px;
  row-gap: 56px;
  /* background-color: red; */
  margin-top: 56px;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 86px;
}

.list-card {
  width: 300px;
  height: 300px;
  border: 2px solid #213200;
  border-radius: 30px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 41px;
  padding-bottom: 41px;
}
.list-card h4 {
  font-size: var(--font-size-lx);
  color: var(--white);
  line-height: 30px;
  margin-top: 24px;
  font-weight: 500;
  font-family: var(--font1);
}
.list-card p {
  font-size: var(--font-size-medium);
  font-weight: 400;
  font-family: var(--font1);
  color: var(--white);
  margin-top: 24px;
  line-height: 24px;
}
.headerBar {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tk-reg-body {
  width: 100%;
  min-height: 100vh;
  padding-bottom: 190px;
  padding-left: 30px;
  padding-right: 30px;
  background-color: #000000;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

.backBtn {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #ffffff;
  font-size: var(--font-size-medium);
  font-family: var(--font1);
  font-weight: 500;
}
.pageCont {
  width: 679px;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.mTop40 {
  margin-top: 40px;
}
.pageH4 {
  font-size: var(--font-size-lx);
  color: var(--white);
  font-family: var(--font1);
  line-height: 24px;
  font-weight: 500;
}
.pageP {
  margin-top: var(--mTop2);
  font-size: var(--font-size-medium);
  line-height: 24px;
  font-weight: 400;
  color: var(--grayL2);
  font-family: var(--font1);
  text-align: center;
}
.inputCont {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: var(--mTop3);
}
.inputCont1 {
  width: 100%;
  margin-top: 18px;
}
.inputWrap {
  width: 48.6%;
}
/* input css */
.input-container {
  position: relative;
  width: 100%;
  background: var(--deepDark);
  height: 72px;
  border-radius: 16px;
  /* margin: 20px 0; */
}

.floating-label {
  position: absolute;
  left: 24px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  color: #a5a5a5;
  transition: all 0.2s ease;
  /* pointer-events: none; */
  font-family: var(--font1);
  font-weight: 500;
}
.floating-input {
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
  font-size: var(--font-size-mediumL);
  font-family: var(--font1);
  font-weight: 500;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: none;
  padding-top: 20px;
  height: 72px;
  border-radius: 16px;
  background: none;
  outline: none;
  color: var(--white) !important; /* Ensure text color is white by default */
  letter-spacing: 0px;
}

/* Autofill styles for webkit browsers */
.floating-input:-webkit-autofill {
  background-color: var(--deepDark) !important; /* Match input background */
  color: var(--white) !important;
  font-size: var(--font-size-mediumL);
  font-family: var(--font1);
  border-radius: 16px;
  -webkit-text-fill-color: var(--white) !important;
  transition: background-color 5000s ease-in-out 0s;
}

.floating-input:focus {
  border-color: #007bff;
}

.input-container.focused .floating-label {
  top: 23px;
  left: 24px;
  font-size: 14px;
  color: #a5a5a5;
  /* line-height: 24px; */
}
.textarea {
  padding-top: 35px;
  min-height: 72px;
  /* height: auto !important; */
  flex-grow: 1;
  /* overflow: hidden; */
  resize: none;
  padding-bottom: 16px;
}
.textarea1 {
  padding-top: 35px;
  min-height: 72px;
}
.bottomCont {
  margin-top: 48px;
  width: 100%;
}
.checkCont {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  /* justify-content: center; */
}
.checkCont p {
  font-size: 15px;
  font-family: var(--font1);
  color: var(--darkGray);
  font-weight: 400;
  margin-left: 20px;
}
.checkBtn {
  cursor: pointer;
}
.t-span {
  color: #e6e6e5;
  text-decoration: underline;
  cursor: pointer;
  font-family: var(--font1);
}
.sec-btn {
  width: 100%;
  height: 56px;
  border-radius: 16px;
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-medium);
  line-height: 24px;
  font-family: var(--font1);
  font-weight: 600;
  cursor: pointer;
  border: none;
}
.btn-disabled {
  background-color: var(--dark) !important;
  color: #4a4a4a !important;
}
.linkText {
  font-size: 17px;
  font-weight: 400;
  font-family: var(--font1);
  color: var(--grayL2);
  text-align: center;
  margin-top: 30px;
}
.link {
  color: var(--primaryColor);
  font-weight: 600;
  cursor: pointer;
  margin-left: 8px;
}

/* email sent screen */
.emailBody {
  width: 100%;
  min-height: 100vh;
  background-color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.envelop {
  /* margin-top: 220px; */
  width: 160px;
  height: 160px;
}
.textWrap h4 {
  font-family: var(--font1);
  font-size: 36px;
  line-height: 24px;
  font-weight: 500;
  text-align: center;
  color: var(--white);
  margin-top: 56px;
}
.textWrap p {
  font-size: 17px;
  line-height: 24px;
  font-family: var(--font1);
  font-weight: 400;
  text-align: center;
  color: var(--grayL2);
  margin-top: 20px;
}
.btn-Wrap {
  width: 340px;
  margin-top: 48px;
}

.pageCont1 {
  margin-top: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.pageP1 {
  margin-top: 30px;
}
.email-span {
  color: #ffffff;
  font-size: 17px;
  font-family: 400;
}
.otp-cont {
  display: flex;
  margin-top: var(--mTop3);
}
.otp-input {
  width: 72px;
  height: 72px;
  background: var(--deepDark);
  margin-right: 12px;
  border-radius: 10px;
  border: 1.5px solid transparent;
}
.otp-input input {
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  font-family: var(--font1);
  line-height: 28px;
}
.resend-timer {
  font-size: 16px !important;
  letter-spacing: 0%;
  font-weight: 400;
  color: var(--grayL2);
}
.seconds-timer {
  font-weight: 500;
  color: var(--primaryColor) !important;
}
.rsnd {
  font-size: 17px;
  /* margin-bottom: 80px !important; */
  cursor: pointer;
}
.timer-cont {
  margin-top: 40px;
}
.input-cont {
  margin-top: 48px;
}
.reg-file-cont {
  width: 100%;
  height: 72px;
  border-radius: 16px;
  background-color: var(--deepDark);
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
  padding-right: 24px;
  position: relative;
  transition: all 0.3s ease;
}
.floating-label1 {
  position: absolute;
  left: 24px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  color: #a5a5a5;
  transition: all 0.2s ease;
  /* pointer-events: none; */
  font-family: var(--font1);
  font-weight: 500;
}
.focused1 {
  height: 106px;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 24px;
  position: relative;
}
.reg-file-cont.focused1 .floating-label1 {
  top: 23px;
  left: 24px;
  font-size: 14px;
  color: #a5a5a5;
  /* background: red; */
}
.reg-file-cont.focused .floating-label {
  top: 23px;
  left: 24px;
  font-size: 14px;
  color: #a5a5a5;
}
.rounded-check {
  position: absolute;
  right: 24px;
  top: 10px;
}
.upload-btn {
  width: 87px;
  height: 36px;
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  font-size: 13px;
  font-family: var(--font1);
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
}
.file-holder {
  width: 100%;
  border-top: 1px solid #1d1d1d;
  bottom: 10px;
  height: 56px;
  padding-top: 16px;
  padding-bottom: 10px;
}
.holding-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.drop-down {
  cursor: pointer;
}
.fileName-cont {
  display: flex;
  align-items: center;
  /* gap: 12px; */
  color: var(--white);
  font-size: 16px;
  font-family: var(--font1);
  font-weight: 400;
}
.fileName-cont img {
  margin-right: 12px;
}
.valid-password {
  display: flex;
  width: 100%;
  margin-top: 12px;
  gap: 24px;
}
.vp-cont {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #838383;
}
.input-cont-vp {
  margin-top: 42px;
}
.input-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
}
.toggle-password-visibility {
  position: absolute;
  right: 24px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}
.fp {
  width: 100%;
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
}
.fp p {
  color: var(--primaryColor);
  font-size: 15px;
  font-family: var(--font1);
  font-weight: 500;
  cursor: pointer;
}
/* Toast container styles */
.toast-container {
  position: fixed;
  top: 80px;
  right: -800px; /* Start off-screen */
  z-index: 10000000;
  width: 343px;
  min-height: 60px;
  transition: transform 0.3s ease, opacity 0.3s ease;
  transition: right 0.3s ease;
  display: flex;
  align-items: center;
  border-radius: 16px;
  border: 1.5px solid transparent;
  padding-left: 16px;
}

.toast-container.show {
  right: 37px; /* Position on-screen when shown */
  animation: slideIn 0.5s forwards;
}

.toast-container:not(.show) {
  animation: slideOut 0.5s forwards;
  right: -400px;
}

/* Toast card styles */
.toast-card {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #fff;
  color: #333;
  padding: 15px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

.toast-card.success {
  border-left: 4px solid #28a745;
}

.toast-card.error {
  border-left: 4px solid #dc3545;
}

.toast-card .icon {
  width: 24px;
  height: 24px;
}

.toast-header-text {
  font-size: 16px;
  font-weight: 500;
  font-family: var(--fontInter);
  margin-left: 13px;
}

/* Close button styles */
.toast-close-btn {
  background: none;
  border: none;
  font-size: 18px;
  font-weight: bold;
  color: #999;
  cursor: pointer;
  position: absolute;
  right: 22px;
}

.toast-close-btn:hover {
  color: #333;
}

/* Keyframe animations */
@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
    /* right: -400px;  */
  }
  to {
    transform: translateX(0);
    opacity: 1;
    /* right: 37px; */
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}
.inputError {
  border: 1.4px solid #ba2543;
}
.error-text {
  color: #ba2543 !important;
  font-size: 15px !important;
  font-family: var(--font1);
  font-weight: 400 !important;
  margin-top: 16px;
}
.mt-8 {
  margin-top: 4px;
}
.cus {
  cursor: not-allowed;
}
.dot-loader {
  display: flex;
  gap: 5px;
}

.dot {
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  animation: dotPulse 1.5s infinite ease-in-out;
}

.dot:nth-child(1) {
  animation-delay: 0s;
}
.dot:nth-child(2) {
  animation-delay: 0.2s;
}
.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes dotPulse {
  0%,
  80%,
  100% {
    transform: scale(0.8);
    opacity: 0.7;
  }
  40% {
    transform: scale(1.2);
    opacity: 1;
  }
}
.mTop56 {
  margin-top: 56px;
}
.custom-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 50%;
  background-color: white;
  overflow: hidden;
  border: 0;
}
.custom-spinner-arc {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border-style: solid;
  position: absolute;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.in-drop-down {
  width: 362px;
  max-height: 300px;
  background-color: var(--dark);
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 12px;
  padding-bottom: 24px;
  overflow: auto;
  margin-top: 7px;
  position: absolute;
  border-radius: 8px;
  z-index: 10;
}
.in-drop-down::-webkit-scrollbar-track {
  display: none;
}
.in-drop-down::-webkit-scrollbar-button {
  display: none;
}
.in-drop-down::-webkit-scrollbar {
  width: 5px;
}
.ind {
  width: 100%;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  margin-bottom: 12px;
  color: #ffffff;
  font-weight: 400;
  font-size: 15px;
  font-family: var(--font1);
  border-radius: 4px;
  cursor: pointer;
  transition: 0.2s background-color ease-in-out;
}

.ind:hover {
  background-color: #ffffff;
  color: #000000;
  border: 0;
}

.ind-active {
  background-color: #ffffff;
  color: #000000;
  border: 0;
}
.active-ind {
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  font-family: var(--font1);
  padding-left: 24px;
  padding-top: 25px;
}
.side-nav {
  width: 16.5%;
  height: 100vh;
  background: #151515;
  transition: width 0.5s ease;
  overflow: hidden;
  padding-left: 13px;
  padding-top: 30px;
  position: fixed;
  /* top: 0;
  left: 0; */
}
.side-nav1 {
  width: 16.5%;
  height: 100vh;
  background: transparent;
  transition: width 0.5s ease;
  overflow: hidden;
  padding-left: 13px;
  padding-top: 30px;
}
.side-expand {
  width: 100px;
}
.logo-wrap {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 15px;
}
.logo-wrap p {
  font-size: 24px;
  color: #a7fc00;
  font-weight: 500;
  font-family: var(--font1);
}
.side-link-list {
  margin-top: 56px;
  overflow-y: hidden;
  height: 85vh;
  transition: overflow-y 0.2s ease-in-out;
  /* background: #007bff; */
}

.side-link-list:hover {
  overflow-y: auto;
}

.side-link-list::-webkit-scrollbar-track {
  background: transparent;
  display: none;
}
.side-link-list::-webkit-scrollbar-button {
  display: none;
}
.side-link-list::-webkit-scrollbar {
  width: 6px;
  padding-left: 2px;
  padding-right: 2px;
}

.side-link-list::-webkit-scrollbar-thumb {
  background-color: #555; /* Thumb color */
  border: 3px solid transparent;
  /* background-clip: padding-box; */
}
.side-link {
  width: 100%;
  /* background: red; */
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-bottom: 10px;
  position: relative;
  cursor: pointer;
  gap: 16px;
  display: flex;
  align-items: center;
}
.side-link p {
  color: #b3b4b3;
  font-family: 400;
  font-family: var(--font1);
  font-size: 16px;
}
.overlay {
  position: absolute;
  bottom: 0;
  left: 100%;
  right: 0;
  background-color: #222222;
  overflow: hidden;
  width: 0;
  height: 100%;
  transition: 0.5s ease;
  z-index: -1;
}
.side-img {
  color: #b3b4b3;
}
.active .overlay {
  width: 100%;
  left: 0;
  border-left: 3px solid #a7fc00;
}
.active .side-img {
  color: #fff;
}
.active p {
  color: #fff;
}
.side-link:hover .side-img {
  color: #fff;
}
.side-link:hover .overlay {
  width: 100%;
  left: 0;
  border-left: 3px solid #a7fc00;
}
.side-link:hover p {
  color: #ffffff;
}
.side-comp {
  /* width: 16.5%; */
}
.dash-body {
  width: 100%;
  min-height: 100vh;
  background: #000000;
  flex-grow: 1;
  display: flex;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.content-body {
  width: 83.5%;
  min-height: 100vh;
  padding-left: 40px;
  padding-right: 32px;
  padding-bottom: 36px;
  position: relative;
  padding-top: 92px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  /* background-color: #; */
}
.pr-nav {
  width: 79%;
  height: 92px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  flex-grow: 0;
  top: 0;
  /* background-color: red; */
  z-index: 1000;
}
.custom-input {
  width: 298px;
  height: 44px;
  background-color: #181818;
  border-radius: 100px;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 10;
  transition: all 0.5s ease;
}
.custom-input input {
  height: 44px;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 16px;
  color: var(--white);
  font-weight: 400;
  font-family: var(--font1);
}
.custom-input input::placeholder {
  color: #838383;
}
.noti-profile {
  width: 96px;
  /* height: 40px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.profile-img {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  object-fit: cover;
}
.noti-profile div {
  cursor: pointer;
}
.section-ph {
  width: 100%;
  margin-top: 26px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.ph-text-sec {
  /* gap: 10px; */
  /* column-gap: 10px; */
}
.ph-text-sec h4 {
  font-size: 32px;
  color: #fff;
  font-family: var(--font1);
  font-weight: 600;
  margin-bottom: 10px;
}
.ph-text-sec p {
  font-size: var(--font-size-medium);
  font-family: var(--font1);
  font-weight: 400;
  color: #b3b4b3;
}
.lnp-btn {
  padding: 12px;
  background-color: #ffffff;
  color: #000000;
  font-size: 15px;
  border-radius: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}
.mega-search {
  width: 0;
  height: 62px;
  border-radius: 12px;
  margin-top: 18px;
  background-color: #181818;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: space-between;
  transition: width 1s ease-in-out;
}
.mega-search.sExpanded {
  width: 100%;
  /* background-color: red; */
}
.s-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
}
.search-nim {
  width: 75%;
  /* background: red; */
}
.mega-search input {
  height: 48px;
  width: 90%;
  outline: none;
  border: none;
  color: var(--white);
  background-color: transparent;
  font-weight: 400;
  font-family: var(--font1);
  font-size: 14px;
}
.actions-wrap {
  display: flex;
  align-items: center;
}
.filter {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 16px;
  padding-right: 16px;
  gap: 8px;
  display: flex;
  align-items: center;
  color: #616161;
  font-size: 14px;
  font-weight: 400;
  font-family: var(--font1);
  border: 1px solid #343434;
  border-radius: 8px;
  cursor: pointer;
  margin-right: 14px;
}
.action-icon {
  width: 40px;
  height: 40px;
  color: #343434;
  border-radius: 8px;
  border: 1px solid #343434;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.icon1 {
  margin-right: 5px;
}
.action-active {
  color: #b3b4b3;
  background-color: #343434;
}
.pr-list-body {
  width: 100%;
  height: 62vh;
  overflow-y: auto;
  background-color: #181818;
  margin-top: 16px;
  border-radius: 12px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 50px;
}
.new-pr-height {
  height: 72vh;
  padding-top: 22px;
}
.pr-list-body::-webkit-scrollbar-track {
  background-color: transparent;
}
.pr-list-body::-webkit-scrollbar-button {
  background-color: transparent;
}
.pr-list-body::-webkit-scrollbar {
  width: 7px;
}
thead tr {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #181818;
}
.table {
  width: 100%;
  border-collapse: collapse;
  animation: fadeIn 0.3s ease-in-out;
  /* background: red; */
}

.table th {
  padding-top: 17px;
  padding-bottom: 17px;
  text-align: left;
  color: #b3b4b3;
  font-size: 15px;
  font-weight: 400;
  font-family: var(--font1);
}
.table td {
  /* padding: 15px; */
  /* border-bottom: 1px solid #444; */
}
.tr {
  background-color: #141414;
  margin-bottom: 8px;
  border-top-left-radius: 18px;
  border-bottom-left-radius: 18px;
  padding-left: 20px;
  padding-top: 18px;
  padding-bottom: 18px;
  padding-right: 10px;
  border: none;
  height: 84px;
  display: flex;
}
.tr img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 100px;
  margin-right: 12px;
}
.tr-text p {
  font-size: 16px;
  color: #ffffff;
  font-family: var(--font1);
  font-weight: 500;
  margin-bottom: 2px;
}
.tr-text span {
  font-size: 15px;
  color: #838383;
  line-height: 22px;
}
.tr1 {
  background-color: #141414;
  margin-bottom: 8px;
  padding-top: 18px;
  padding-bottom: 18px;
  height: 84px;
  display: flex;
  align-items: center;
  font-size: 15px;
  color: var(--white);
  font-weight: 400;
}
.tr-last {
  background-color: #141414;
  margin-bottom: 8px;
  border-top-right-radius: 18px;
  border-bottom-right-radius: 18px;
  padding-right: 20px;
  padding-top: 18px;
  padding-bottom: 18px;
  /* padding-right: 20px; */
  border: none;
  height: 84px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 15px;
  color: var(--white);
  font-weight: 400;
}
.f-item {
  color: #29b358;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 100px;
  border: 1px solid rgba(41, 179, 88, 0.24);
  line-height: 24px;
  background-color: rgba(41, 179, 88, 0.08);
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.f-item-error {
  color: #ba2543;
  background-color: rgba(186, 37, 67, 0.08);
  border: 1px solid rgba(186, 37, 67, 0.35);
  padding-left: 12px;
  padding-right: 12px;
}
.f-item-prog {
  color: #ea7e1a;
  background-color: rgba(234, 126, 26, 0.08);
  border: 1px solid rgba(234, 126, 26, 0.24);
  padding-left: 12px;
  padding-right: 12px;
}
.f-item-draft {
  color: #1f81b8;
  background-color: rgba(31, 129, 184, 0.08);
  border: 1px solid rgba(31, 129, 184, 0.24);
  padding-left: 12px;
  padding-right: 12px;
}
.prog-bar-cont {
  display: flex;
  align-items: center;
}
.prog-bar {
  width: 156px;
  height: 5px;
  border-radius: 100px;
  background-color: #4a4a4a;
  margin-right: 12px;
}
.bar {
  width: 0;
  height: 100%;
  background-color: #a7fc00;
  border-radius: 100px;
  transition: width 0.5s ease-in-out;
}
/* Grid View Styling */
.grid-view {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  animation: fadeIn 0.3s ease-in-out;
  margin-top: 21px;
  align-items: center;
}

.grid-card {
  flex: 1 1 calc(33.333% - 16px);
  max-width: calc(33.333% - 16px);
  background: #141414;
  padding: 18px;
  /* height: 276px; */
  border-radius: 10px;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); */
  transition: transform 0.3s ease-in-out;
}
.wrap-sec1 {
  display: flex;
  gap: 12px;
}
.wrap-sec1 img {
  width: 80px;
  height: 80px;
  border-radius: 12px;
  object-fit: cover;
}
.grid-t-list {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.grid-t-list p {
  font-size: 15px;
  color: #ffffff;
  font-weight: 400;
  font-family: var(--font1);
}
.grid-prog-bar {
  width: 125px;
}
.grid-card h3 {
  margin-bottom: 10px;
}
.mTop12 {
  margin-top: 12px;
}

.grid-card p {
  margin: 5px 0;
}
.view-d {
  width: 97px;
  /* padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 12px;
  padding-right: 12px; */
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #4a4a4a;
  font-size: 13px;
  font-weight: 500;
  font-family: var(--font1);
  color: #ffffff;
  cursor: pointer;
  margin-top: 12px;
  height: 32px;
}
.grid-card:hover {
  transform: scale(1.05);
}

/* Animations */
/* @keyframes fadeIn2 {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
} */

.empty-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.empty-wrap p {
  margin-top: 11px;
  font-size: var(--font-size-medium);
  color: var(--white);
  font-weight: 500;
  font-family: var(--font1);
}
.empty-wrap span {
  margin-top: 4px;
  font-size: 14px;
  font-weight: 400;
  font-family: var(--font1);
  color: #838383;
}
.f-item-load {
  width: 108px;
  height: 12px;
  border-radius: 100px;
  background: #4a4a4a;
}
.d-load {
  width: 108px;
  height: 9px;
  border-radius: 100px;
  background: #4a4a4a;
}
.img-load {
  width: 40px;
  height: 40px;
  background: #4a4a4a;
  border-radius: 100px;
  margin-right: 12px;
}
.t-load {
  width: 108px;
  height: 9px;
  border-radius: 100px;
  background: #4a4a4a;
}
.in-load {
  width: 75px;
  height: 5px;
  background: #4a4a4a;
  border-radius: 100px;
  margin-top: 4px;
}
.prog-load {
  width: 20px;
  height: 5px;
  background-color: #838383;
  border-radius: 100px;
}
.tr-text {
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.list-option-body {
  margin-top: 50px;
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.list-option-body h4 {
  font-size: 20px;
  color: var(--white);
  font-weight: 500;
  margin-bottom: 64px;
}
.pr-item-cont {
  width: 373px;
  padding: 24px;
  display: flex;
  align-items: center;
  height: 135px;
  background-color: var(--deepDark);
  margin-bottom: 16px;
  border-radius: 12px;
  border: 1px solid #1d1d1d;
  justify-content: space-between;
  cursor: pointer;
  transition: 0.5s ease-in-out;
}
.pr-item-cont:hover {
  background-color: transparent;
  border: 1px solid #a7fc00;
}
.pr-active {
  background-color: transparent;
  border: 1px solid #a7fc00;
}
.pr-btn {
  width: 373px;
  height: 42px;
  margin-top: 8px;
}
.m-pr-btn {
  background-color: #a7fc00;
  font-family: var(--font1);
}
.pr-text-wrap h4 {
  font-size: 16px;
  font-weight: 600;
  color: #d9dad9;
  font-family: var(--font1);
  margin-bottom: 17px;
}
.pr-text-wrap p {
  font-size: 14px;
  font-weight: 400;
  font-family: var(--font1);
  color: #838383;
  line-height: 17px;
}
.pr-text-wrap {
  max-width: 253px;
}
.dash-overview {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card-holder {
  width: 42.3%;
  /* 56.3 */
}
.card-item {
  width: 48.3%;
  height: 135px;
  background-color: #181818;
  border-radius: 12px;
  padding-top: 16px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.chart-holder {
  width: 56.3%;
  height: 277px !important;
  border-radius: 12px;
  background-color: #181818;
  padding-left: 20px;
  padding-bottom: 16px;
  padding-top: 16px;
  padding-right: 20px;
}
.holder1 {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.holder2 {
  /* margin-bottom: 16px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.t1-holder {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.t1-holder p {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  font-family: var(--font1);
}
.lp-cont {
  width: 100%;
  height: 600px;
  background: #181818;
  margin-top: 40px;
  border-radius: 12px;
  margin-bottom: 52px;
  padding-top: 20px;
  padding-bottom: 20px;
  /* overflow-y: auto; */
  position: relative;
}

.sk-load {
  width: 108px;
  height: 12px;
  border-radius: 100px;
  background-color: #4a4a4a;
  margin-bottom: 22px;
  /* Add the animation */
  animation: blinker 2s infinite;
}

@keyframes blinker {
  0%,
  100% {
    opacity: 1; /* Fully visible */
  }
  50% {
    opacity: 0.3; /* Invisible */
  }
}
.count-value {
  font-size: 28px;
  color: #fff;
  font-weight: 500;
}
.v1 {
  font-size: 24px;
}

.range-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;

  border-radius: 5px;
  outline: none;
  /* overflow: hidden; */
  cursor: pointer;
}
.custom-slider {
  width: 100%;
  height: 4px;
  background: #4a4a4a;
  border-radius: 100px;
  margin-top: 14px;
  position: relative;
}
.custom-slider .track {
  height: 100%;
  position: relative;
  background: var(--primaryColor);
  border-radius: 100px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  /* padding-right: 16px; */
}
.thumb {
  width: 16px;
  height: 16px;
  background: #fff;
  position: absolute;
  right: 0;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.thumb-inner {
  width: 8px;
  height: 8px;
  background-color: #181818;
  border-radius: 100px;
}
.lp-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding-top: 20px; */
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  /* position: fixed; */
  /* top: 10px; */
}

.lp-header h4 {
  font-size: 24px;
  color: #fff;
  font-weight: 500;
}
.vr-btn {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 8px;
  border: 1px solid #4a4a4a;
  cursor: pointer;
  color: var(--white);
  font-size: 13px;
  font-weight: 500;
  transition: 0.2s padding ease-in-out;
}
.vr-btn:hover {
  padding-left: 14px;
  padding-right: 14px;
}
.lp-content-holder {
  overflow-y: auto;
  height: 500px;
  padding-left: 20px;
  padding-right: 20px;
}
.lp-content-holder::-webkit-scrollbar-track {
  background-color: transparent;
}
.lp-content-holder::-webkit-scrollbar-button {
  background-color: transparent;
}
.lp-content-holder::-webkit-scrollbar {
  width: 7px;
}
.card-anim {
  width: 48.3% !important;
}
.ch-header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.ch-header p {
  font-size: 16px;
  font-weight: 400;
  color: var(--white);
}
.drop-btn {
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: #2c2c2c;
  color: var(--white);
  font-size: 13px;
  font-weight: 500;
  border-radius: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.drop-holder {
  /* min-width:  118px; */
}
.duration-drop {
  width: 130px;
  position: absolute;
  z-index: 5;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 12px;
  background-color: var(--deepDark);
  /* margin-top: 4px; */
  border-radius: 8px;
  top: 38px;
}
.duration-text {
  font-size: 13px !important;
  font-weight: 500;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  background: transparent;
  transition: background ease-in-out;
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
}
.duration-text:hover {
  background: #fff;
  color: #000;
}
.duration-active {
  background: #fff !important;
  color: #000 !important;
}
.drop-btn img {
  margin-left: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.ch-header2 {
  margin-top: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.ch-header2 p {
  color: var(--white);
  font-size: 28px;
  font-weight: 500;
  font-family: var(--font1);
  letter-spacing: 0px;
}
.active-crbs {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 18px;
}
.crbs {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: var(--white);
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.crbs p {
}
.main-chart-cont {
  width: 100%;
  margin-top: 33px;
}
.cload-dash {
  width: 100%;
  height: 4px;
  background-color: #4a4a4a;
  border-radius: 100px;
  animation: blinker 2s infinite;
}
.bottom-26 {
  margin-bottom: 26px;
}
.item-126 {
  height: 126px !important;
}
.c-back {
  margin-top: 26px;
  width: 100%;
  gap: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.c-back p {
  color: var(--white);
  font-size: 16px;
  font-weight: 500;
}

.sec-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  margin-bottom: 11px;
}
.sec-header p {
  font-size: 24px;
  font-weight: 700;
  color: var(--white);
  font-family: var(--font1);
}
.list-content-holder {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}
.content-cont {
  width: 100%;
  padding: 20px;
  background-color: #181818;
  border-radius: 12px;
  margin-bottom: 24px;
}
.list-input {
  /* background-color: #0d0d0d !important; */
  height: 64px !important;
  padding-top: 25px;
}
.list-input-cont.focused .floating-label {
  top: 20px;
}
.img-upload-cont {
  width: 100%;
  height: 169px;
  transition: 0.5 height ease;
  background-color: #0d0d0d !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
  margin-top: 16px;
  justify-content: center;
  cursor: pointer;
  position: relative;
}
.img-upload-cont1 {
  height: 251px;
}
.img-upload-cont p {
  font-size: 12px;
  color: #d9dad9;
  line-height: 14px;
  font-weight: 400;
}
.img-upload-cont span {
  font-size: 12px;
  color: #838383;
  line-height: 14px;
  font-weight: 400;
}
.img-upload-cont img {
  margin-bottom: 8px;
}
.abt-talent-cont {
  width: 100%;
  min-height: 127px;
  background-color: #0d0d0d !important;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 16px;
  border-radius: 16px;
  padding-bottom: 16px;
}
.abt-label {
  padding-top: 16px;
  font-size: 16px;
  color: #a5a5a5;
  font-weight: 500;
  transition: 0.2s font-size ease;
}
.abt-label1 {
  padding-top: 16px;
  font-size: 14px;
  color: #a5a5a5;
  font-weight: 400;
}
.abt-talent-text {
  width: 100%;
  background: none;
  border: none;
  color: var(--white);
  font-size: 16px;
  font-weight: 500;
  font-family: var(--font1);
  outline: none;
  margin-top: 10px;
  height: auto; /* Allow dynamic height */
  max-height: 300px; /* Cap the height */
  overflow-y: auto;
  resize: none; /* Remove resize handle */
  box-sizing: border-box; /* Include padding in height calculation */
}

.abt-talent-text::-webkit-scrollbar-track {
  background-color: transparent;
}
.abt-talent-text::-webkit-scrollbar-button {
  display: none;
}
.abt-talent-text::-webkit-scrollbar-thumb {
  cursor: pointer;
}
.ov {
  font-size: 18px;
  font-weight: 400;
  color: var(--white);
}
.option-holders {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}
.dropdown-holder {
  width: 49.3%;
}
.dropdown-holder .drop-down {
  height: 64px !important;
  background-color: #0d0d0d !important;
}
.dropdown-holder .in-drop-down {
  background-color: #0d0d0d;
}
.input-holder {
  width: 49.3%;
}
.dash {
  width: 100%;
  border-bottom: 1px solid #1d1d1d;
  margin-top: 16px;
}
.inc-btn-cont {
  margin-top: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.add-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  gap: 14px;
  font-size: 14px;
  color: #a7fc00;
  cursor: pointer;
}
.sub-btn {
  color: #ba2543;
}
.skills-cont {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1.7%;
  flex-wrap: wrap;
}
.skill-input {
  width: 31.9%;
  margin-top: 16px;
}
.cc1 {
  margin-top: 16px;
}
.cc {
  margin-top: 4px;
}
.std-cont {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
}
.std-btn {
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 12px;
  padding-bottom: 12px;
  border: 1px solid #4a4a4a;
  border-radius: 12px;
  font-size: 14px;
  color: var(--white);
  font-weight: 500;
  cursor: pointer;
}
.c-btn {
  width: 100px;
  height: 42px;
  /* padding-left: 14px;
  padding-right: 14px;
  padding-top: 12px;
  padding-bottom: 12px; */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primaryColor);
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 12px;
  border: none;
}
.c-disabled {
  background-color: var(--dark) !important;
}
.rf {
  width: 100%;
  margin-top: 36px;
}
.rf p {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}
.rf-input {
  background-color: #0d0d0d !important;
}
.tt-cont {
  margin-top: 16px;
}
.logout-pop {
  width: 100%;
  height: 100vh;
  position: fixed;
  background-color: #030500cf;
  z-index: 20000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logout-card {
  width: 633px;
  height: 191px;
  background-color: #181818;
  border-radius: 12px;
  overflow: hidden;
}
.logout-header {
  width: 100%;
  height: 57px;
  background-color: #262626;
  border-bottom: 1px solid #1d1d1d;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 24px;
}
.logout-header p {
  font-size: 18px;
  color: var(--white);
  font-weight: 500;
}
.logout-header img {
  cursor: pointer;
}
.logout-content {
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
}
.logout-text {
  color: var(--white);
  font-size: 16px;
  list-style: 24px;
  margin-top: 28px;
  font-weight: 400;
}
.logout-action-cont {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 28px;
  gap: 16px;
}
.logout-action-btn {
  width: 65px;
  height: 42px;
  border-radius: 12px;
  border: 1px solid #4a4a4a;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 500;
  color: var(--white);
  cursor: pointer;
}

.logout-btn {
  width: 74px;
  border: none;
  background-color: #ba2543;
}
.upp-nav {
  width: 100%;
  position: absolute;
  /* background-color: red; */
  top: 24px;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.upp-nav p {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  color: #a5a5a5;
}
.bbb-nav {
  width: 100%;
  position: absolute;
  /* background-color: red; */
  bottom: 10px;
  padding-top: 16px;
  border-top: 1px solid #1d1d1d;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center !important;
}
.nn-wrap {
  display: flex !important;
  align-items: center;
  /* justify-content: center; */
  gap: 12px;
  /* background-color: blue; */
}
.nn-wrap img {
  margin-bottom: 0 !important;
}
.nn-wrap p {
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}
.img-cont-error {
  border: 1px solid #ba2543;
}
.num-holders {
  width: 23.8%;
  height: 126px;
  /* background-color: red; */
}
.v-cards {
  width: 100%;
  height: 100%;
  border-radius: 12px;
}
.v-holder {
  width: 100%;
  padding: 20px;
  background-color: #181818;
  border-radius: 12px;
  min-height: 368px;
  margin-top: 18px;
}
.v-head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.v-head-text {
  font-size: 24px;
  line-height: 29px;
  font-family: var(--font1);
  color: var(--white);
  font-weight: 400;
}
.see-all {
  all: unset;
  color: var(--primaryColor);
  font-size: 14px;
  cursor: pointer;
  font-weight: 400;
  font-family: var(--font1);
}
.v-cards-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.5%;
  margin-top: 26px;
  /* justify-content: space-between; */
}
.voucher-card {
  width: 32.2%;
  height: 270px;
  border-radius: 12px;
  overflow: hidden;
}
.voucher-card img {
  width: 100%;
  height: 50%;
  object-fit: cover;
}
.v-card-b {
  width: 100%;
  height: 50%;
  background-color: #fff;
  position: relative;
}
.h-name {
  font-size: 18px;
  line-height: 30px;
  font-weight: 500;
  color: #1d1d1d;
  text-align: center;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #d9dad9;
}
.v-details-wrap {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
}
.v-details-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}
.v-details-item h4 {
  font-size: 20px;
  font-family: var(--font1);
  font-weight: 500;
  line-height: 26px;
}
.v-details-item p {
  font-size: 14px;
  line-height: 24px;
  font-family: var(--font1);
  font-weight: 500;
}
.v-details-item h5 {
  font-size: 14px;
  line-height: 24px;
  color: #4a4a4a;
  font-family: var(--font1);
  font-weight: 400;
}
.exp {
  font-weight: 400 !important;
  color: #ba2543;
}
.ball {
  width: 36px;
  height: 36px;
  background-color: #000;
  border-radius: 100px;
  position: absolute;
  top: 33px;
}
.b-left {
  left: -5%;
}
.b-right {
  right: -5%;
}
.v-card1 {
  background: #141414;
  height: 304px;
  padding: 18px;
  position: relative;
  overflow: visible;
}
.v-holder2 {
  min-height: 393px;
}
.cb-wrap1 {
  width: 100%;
  display: flex;
  gap: 12px;
}
.cb-wrap1 img {
  width: 80px;
  height: 80px;
  border-radius: 12px;
}
.cb-wrap1-b {
  width: 71%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.cb-wrap1-b h4 {
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  font-family: var(--font1);
  font-weight: 500;
}
.cb-wrap1-alt {
  width: 100%;
  /* background: red; */
  display: flex;
  align-items: center;
  margin-top: 8px;
  position: relative;
}
.exp1 {
  color: #ba2543;
  background-color: #ba254314;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 14px;
  line-height: 24px;
  font-family: var(--font1);
  font-weight: 400;
  border-radius: 100px;
  border: 1px solid #ba254359;
}
.cb-wrap1-alt h4 {
  position: absolute;
  right: 0;
  font-size: 12px;
  line-height: 20px;
  color: #cdcdcc;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 6px;
  padding-bottom: 6px;
  background-color: #1d1d1d;
}
.cb-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 12px;
}
.cb-item h4 {
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
}
.cb-item h4:last-child {
  line-height: 24px;
}
.vr-btn1 {
  background-color: transparent;
  margin-top: 12px;
  position: absolute;
  right: 18px;
}
.pbl {
  color: #29b358;
  background-color: #29b35814;
  border-color: #29b3583d;
}
.vd-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
}
.vr-btn2 {
  margin-top: 0 !important;
  background-color: transparent;
  margin-top: 12px;
}
.activity-table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}
.activity-table th {
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: left;
  color: #b3b4b3;
  font-size: 15px;
  font-weight: 400;
  font-family: var(--font1);
}
.tr2 {
  height: 72px;
  color: #fff;
  align-items: center;
}
.av-status {
  color: #29b358;
  background-color: #29b35814;
  border: 1px solid #29b3583d;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 14px;
  line-height: 24px;
  font-family: var(--font1);
  font-weight: 400;
  border-radius: 100px;
}
.av-pending {
  color: #ea7e1a;
  background-color: #ea7e1a14;
  border-color: #ea7e1a3d;
}
.av-rejected {
  border-color: #ba254359;
  color: #ba2543;
  background-color: #ba254314;
}
.th-first {
  padding-left: 20px;
}
.tr-last1 {
  background-color: #141414;
  margin-bottom: 8px;
  border-top-right-radius: 18px;
  border-bottom-right-radius: 18px;
  padding-right: 20px;
  padding-top: 18px;
  padding-bottom: 18px;
  align-items: center;
  border: none;
  height: 72px;
  display: flex;
  font-size: 15px;
  color: var(--white);
  font-weight: 400;
}
.delMenu {
  width: 108px;
  height: 94px;
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #1d1d1d;
  border-radius: 12px;
  position: absolute;
  z-index: 10;
  right: 38%;
  left: 62%;
  bottom: -20%;
}
.delMenu button {
  all: unset;
  color: #fff;
  width: 100%;
  height: 22px;
  padding-left: 10px;
  padding-right: 10px;
  transition: all 0.2s ease;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-radius: 4px;
  font-size: 15px;
  line-height: 22px;
  font-weight: 400;
  font-family: var(--font1);
  cursor: pointer;
  /* text-align: center; */
}
.delMenu button:last-child {
  margin-top: 12px;
}
.delMenu button:hover {
  background-color: #fff;
  color: #000;
}

.voucher-detail {
  background-color: #030500cf;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1000000;
}

.details-card {
  width: 596px;
  height: 577px;
  background-color: #141414;
  border-radius: 12px;
  padding: 20px;
}
.d-card-nav {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.d-card-wrap1 {
  display: flex;
  align-items: center;
}
.d-card-wrap1 img {
  width: 36px;
  height: 36px;
  border-radius: 4px;
  cursor: pointer !important;
}
.d-card-wrap1 div {
  margin-left: 20px;
}
.d-card-wrap1 p {
  font-size: 18px;
  line-height: 24px;
  color: #fff;
  margin-left: 8px;
}

.pr-status {
  padding-top: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 4px;
  font-size: 14px;
  color: #29b358;
  border-radius: 100px;
  color: #29b358;
  background-color: #29b35814;
  border: 1px solid #29b3583d;
  margin-left: 20px;
}
.pr-num {
  font-size: 12px;
  line-height: 20px;
  color: #cdcdcc;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 8px;
  padding-right: 8px;
  background-color: #1d1d1d;
  margin-left: 8px;
  border-radius: 4px;
}
.d-card-nav button {
  all: unset;
  cursor: pointer;
}
.dd-details-cont {
  width: 100%;
  margin-top: 24px;
}
.dd-details-cont h4 {
  color: #ffffff;
  font-size: 18px;
  line-height: 30px;
  font-weight: 500;
}
.dd-details-item {
  margin-top: 20px;
  width: 100%;
  display: flex;
  gap: 24px;
}

.dd-item {
  width: 169px;
  display: flex;
  height: 52px;
  gap: 6px;
}
.dd-item-wrap h4 {
  font-size: 14px;
  line-height: 24px;
  color: #a5a5a5;
  font-weight: 500;
}
.dd-item-wrap p {
  font-size: 14px;
  line-height: 24px;
  color: var(--primaryColor);
  font-weight: 400;
}

.chart-cont {
  width: 100%;
  height: 277px;
  background-color: #181818;
  margin-top: 20px;
  border-radius: 12px;
  padding-left: 20px;
  padding-top: 16px;
  padding-right: 20px;
  padding-bottom: 16px;
}

.rotate {
  transform: rotate(180deg);
}

.exh {
  background-color: #1d1d1d;
  border-color: #fff;
  color: #fff;
}
.dd-btns {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
  gap: 11px;
}
.dd-btns button {
  all: unset;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 12px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #fff;
  cursor: pointer;
}

.dd-btns button:last-child {
  border: none;
  background-color: #ba2543;
}
.del-promt {
  width: 633px;
  height: 215px;
  background-color: #181818;
  border-radius: 12px;
  overflow: hidden;
}
.del-promt-nav {
  height: 57px;
  background-color: #262626;
  border-bottom: 1px solid #1d1d1d;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 24px;
}
.del-promt-nav p {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  font-family: var(--font1);
}

.del-des {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #fff;
  margin-top: 28px;
  padding-left: 24px;
  padding-right: 24px;
}
.del-btns {
  padding-right: 24px;
}

.rep-issue {
  width: 633px;
  height: 446px;
  background-color: #181818;
  border-radius: 12px;
  overflow: hidden;
}

.rep-inputs-cont {
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 24px;
}
.rep-input {
  background-color: #0d0d0d;
}
.rep-input-input {
  height: 63px !important;
}
.floating-label.label-at-top {
  transform: translateY(-50%); /* Adjust this value as needed */
  top: 0; /* Ensure the label is positioned at the top */
  font-size: 12px; /* Match the "focused" label style */
}
.ovo {
  color: #fff;
  margin-top: 32px;
}
.tr-btn {
  all: unset;
  padding-top: 8px;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 13px;
  padding-bottom: 8px;
  border-radius: 8px;
  border: 1px solid #4a4a4a;
  cursor: pointer;
  position: absolute;
  right: 6.5%;
}

.pagination-cont {
  width: 100%;
  background-color: #141414;
  height: 68px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  justify-content: space-between;
}
.pagination-cont button {
  padding-left: 12px !important;
  padding-right: 12px !important;
  padding-top: 8px;
  padding-bottom: 8px;
  all: unset;
  display: flex;
  align-items: center;
  gap: 8px;
  color: #000;
  font-size: 14px;
  line-height: 20px;
  background: #fff;
  font-weight: 500;
  height: 36px;
  border-radius: 8px;
  cursor: pointer;
}
.pagination-cont button:first-child {
  background-color: transparent;
  border: 1px solid #4a4a4a;
  color: #a5a5a5;
}
.pagination-count {
  display: flex;
  align-items: center;
  /* background: red; */
  height: 100%;
  gap: 2px;
}
.pagination-count p {
  width: 40px;
  height: 40px;
  /* background: #fff; */
  text-align: center;
  display: flex;
  transition: all 0.5s ease;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #616161;
}
.pagination-count p:hover {
  color: #fff;
}
.pagination-count p:nth-child(4):hover {
  color: #616161;
}
.req-req {
  height: 669px;
}
.cash-vDetails {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 16px;
}
.tran-details {
  width: 100%;
  margin-top: 20px;
}
.tran-details-item {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
}
.tran-details-item p {
  color: #fff;
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
}
.ovh {
  font-size: 20px;
  font-weight: 600;
}
.new-hold-height {
  min-height: 72vh;
}
.empty-wrap-height {
  height: 72vh;
}
.tab-nav {
  width: 100%;
  height: 41px;
  background-color: #1d1d1d;
  margin-top: 24px;
  border-radius: 7px;
  padding-left: 4px;
  display: flex;
  align-items: center;
  gap: 12px;
}
.tab-nav button {
  all: unset;
  padding-right: 24px;
  padding-left: 24px;
  padding-top: 8px;
  padding-bottom: 8px;
  color: #a5a5a5;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  border-radius: 7px;
  transition: all 0.5s ease;
}
.tab-nav button:hover {
  background: #fff;
  color: #000;
}
.tab-nav .activeTab {
  background: #fff;
  color: #000;
}
.v-history {
  width: 1085px;
  height: 648px;
  background-color: #141414;
  padding-top: 16px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 12px;
}
.v-history-nav {
}

.h-n {
  font-size: 22px;
  line-height: 26px;
  color: #fff;
  font-weight: 500;
}
.v-history-body {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-top: 24px; */
}
.vhis-body1 {
  width: 47%;
}
.update-form {
  width: 100%;
  height: 260px;
  border-radius: 12px;
  background-color: #181818;
  margin-top: 40px;
  padding: 20px;
}
.in-hold {
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
}
.in-hold .inp {
  width: 147px;
}
.inp-input {
  height: 60px;
  background-color: #0d0d0d;
}
.chev img {
  transition: all 0.4s ease;
}

.vhis-body2 {
  height: 497px;
  background: #181818;
  border-radius: 12px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 20px;
}
.vhis-hText {
  color: #ffffff;
  font-size: 18px;
  line-height: 30px;
  font-weight: 500;
}
.custom-th-wrap {
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
}
.custom-th {
  width: 33.3%;
  color: #b3b4b3;
  font-size: 15px;
  font-weight: 400;
}
.custom-td-wrap {
  width: 100%;
  border-radius: 16px;
  background-color: #141414;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 8px;
  display: flex;
  align-items: center;
}
.vHistries-holder {
  width: 100%;
  height: 350px;
  /* background: red; */
  overflow: auto;
}
.vHistries-holder:hover::-webkit-scrollbar {
  display: block;
}
.vHistries-holder::-webkit-scrollbar {
  width: 5px;
  display: none;
}
.vHistries-holder::-webkit-scrollbar-track {
  background: transparent;
}
.vHistries-holder::-webkit-scrollbar-button {
  display: none;
}
.custom-td {
  width: 33.3%;
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
}
.voucher-card-load {
  width: 32.2%;
  height: 270px;
  background-color: #4a4a4a;
  animation: blinker 2s infinite;
  border-radius: 12px;
}
.v-card-load-wrap {
  width: 100%;
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: space-between;
}

.spotlight-details {
  width: 100%;
  height: 758px;
  margin-top: 11px;
  background-color: #181818;
  padding: 20px;
  display: flex;
  justify-content: space-between;
}
.details-wrap1 {
  width: 58.1%;
  overflow-y: hidden;
  height: 100%;
  /* background-color: red; */
  padding-right: 20px;
}
.details-wrap2 {
  width: 39%;
  overflow-y: hidden;
  height: 100%;
  /* background-color: red; */
  /* padding-right: 20px; */
}
.details-wrap2:hover {
  overflow-y: auto;
}
.d-card-wrap2::-webkit-scrollbar-track {
  background-color: #181818 !important;
}
.details-wrap1:hover {
  overflow-y: auto;
}
.d-card-wrap1::-webkit-scrollbar-track {
  background-color: #181818 !important;
}
.dtl-h4 {
  font-size: 1rem;
  color: #fff;
  line-height: 24px;
  font-weight: 600;
}
.dtl-p {
  font-size: 0.875rem;
  color: #b3b4b3;
  line-height: 17px;
  font-weight: 400;
  margin-top: 6px;
}
.dtl-overview {
  margin-top: 40px;
}
.dtl-ovo {
  width: 100%;
  flex-wrap: wrap;
  display: flex;
}
.dtl-item {
  width: 33.3%;
  /* background-color: blue; */
  margin-top: 6px;
}
.f-15 {
  font-size: 15px;
  line-height: 24px;
}
.r-details {
  height: 502px !important;
}

.gallary-wrap {
  width: 100%;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  margin-top: 16px;
}
.gal-hold {
  width: 49%;
}

.sup-frame {
  width: 100%;
  height: 244px;
  border-radius: 20px;
  background-image: url("../assets/supportf.png");
  background-size: cover;
  margin-top: 40px;
  border: 1px solid #1d1d1d;
  padding-top: 28px;
  padding-left: 42px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 28px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  margin-bottom: 30px;
}
.supports {
  display: flex;
  gap: 20px;
}
.supports button {
  all: unset;
  cursor: pointer;
}
.support-text h4 {
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 8px;
}
.support-text p {
  font-size: 16px;
  color: #d9dad9;
  line-height: 24px;
}
.faq-item {
  width: 100%;
  padding: 20px;
  background-color: #0d0d0d;
  border-radius: 12px;
  margin-top: 12px;
  height: auto;
  transition: all 0.5s ease;
}
.faq-item p {
  font-size: 14px;
  color: #cdcdcc;
  font-weight: 400;
}
.faq-header h4 {
  font-size: 16px;
  font-weight: 400 !important;
  color: #fff;
}
.faq-header img {
  transition: all 0.5s ease;
}

.faq-active {
  transform: rotate(180deg);
}
.showSBorder {
  border: 4px solid #537e00 !important;
  width: 539px;
  margin-left: 16px;
  margin-top: 16px;
}
.search-it-holder {
  width: 571px;
  min-height: 304px;
  background-color: #141414;
  border: 1px solid #1d1d1d;
  position: absolute;
  top: 0;
  z-index: -1;
  border-radius: 8px;
  padding-top: 60px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
}

.search-it-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
}
.search-it-header p {
  color: #cdcdcc;
  font-size: 18px;
  font-weight: 400;
}
.search-it-header button {
  all: unset;
  color: var(--primaryColor);
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
}
.search-item {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 8px;
  margin-top: 15px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.ssub-text {
  font-size: 15;
  font-weight: 400;
  color: #838383;
  line-height: 22px;
  margin-top: 2;
}
.search-body {
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.search-item-border {
  border-bottom: 1px solid #141414;
}
.pr-gallary-sec {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1.98%;
}
.pr-gallary-sec img {
  width: 23.5%;
  height: 80px;
  margin-top: 6px;
}
.pr-gallary-sec ol li {
  line-height: 25px;
}
.l-chart--hold {
  background-color: #121212;
}
.ex-link {
  width: 100%;
  height: 56px;
  margin-bottom: 8px;
  cursor: pointer;
  text-decoration: none;
}

.ex-link button {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: none;
  background-color: #121212;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  font-size: 16px;
  line-height: 24px;
}
.ex-link button div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.large-chart {
  width: 100%;
  margin-top: 16px;
  height: 565px;
  background-color: #181818;
  border-radius: 12px;
  padding: 24px;
  position: relative;
}

.pag-active {
  color: #fff;
}
.unpublish {
  border: 1px solid #4a4a4a;
  border-radius: 12px;
  padding: 12px;
  font-size: 15px;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
}

.orp {
  font-family: "ClashGrotesk-Medium";
  /* font-size: 20px; */
}

.marquee {
  animation: marquee 20s linear infinite;
  /* background-color: red; */
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.partners {
  background-color: "red";
}
body {
  background-color: #000;
}

.footer {
  min-height: 533px;
  /* background-color: #000 !important; */
  border-top: 1px solid #ffffff1c;
  background-image: url("../assets/FooterImg.png");
  background-size: cover;
  padding-left: 8.5%;
  padding-right: 8.5%;
}
.b1-sec {
  padding-left: 8.5%;
  padding-right: 8.5%;
}
.footer-cont{
  /* background: red; */
  margin-top: 80px;
  flex-wrap: wrap;
}
.l-card{
  width: 243px;
}
.card-p{
  font-family: "ClashGrotesk-Regular";
  line-height: 25px;
}
.copyright{
  font-weight: 300;
  margin-top: 1.6rem;
}
.f-link-section{
  display: flex;
  /* align-items: center; */
  gap: 5.4rem;
}
.card-head {
  font-size: 18px;
  font-family: "ClashGrotesk-Medium";
  color: #fff;
  letter-spacing: 0%;
  margin-bottom: 24px;
}
.card-lp {
  font-size: 14px;
  color: #fff;
  font-weight: 400;
  font-family: "Red Hat Text", sans-serif;
  font-weight: 400;
}
.socials {
  margin-top: 1.1rem;
}
.s-card-head {
  font-size: 16px;
  font-family: "ClashGrotesk-Medium";
  color: #fff;
  letter-spacing: 0%;
}
.s-icons {
  display: flex;
  align-items: center;
  margin-top: 13px;
}
.mt-13{
  margin-top: 13px;
}

.prt-img{
  width: 100px;
}
.prt-img2{
  width: 200px;
}
.backed-by img{
width: 100px;
}