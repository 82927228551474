p,
li,
h1,
h2,
h3,
h4,
h5 {
    font-family: "Figtree", sans-serif;
}

.notification-list-view {
    width: 100%;
    border-bottom: 1px solid #1D1D1D;
}

.notification-container {
    width: 400px;
    z-index: 1;
    background-color: #141414;
    position: absolute;
    top: 90px;
    right: 50px;
}


.notification-title-view {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    border-bottom: 1px solid #1D1D1D;
}

.close-icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.notification-title {
    font-size: 16px;
    color: #FFFFFF;
    font-weight: 600;

}


.notification-list-container {
    width: 100%;
    padding: 12px 21px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 12px;
    cursor: pointer;
}

.notification-list-icon-view {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center
}

.notification-list-icon {
    width: 32px;
    height: 32px;
}

.notification-info-view {
    width: 308px;
}

.notification-info-title {
    color: #FFFFFF;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
}

.notification-info-description {
    color: #A5A5A5;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    width: auto;
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: nowrap;
}

.notification-list-action-view {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
}

.notification-dismiss {
    color: #A7FC00;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    cursor: pointer;
}

.notification-time {
    color: #A5A5A5;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
}

.mark-read-view {
    width: 100%;
    padding: 12px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.notification-see-all {
    color: #A7FC00;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    cursor: pointer;
}

.notification-mark-read-text {
    color: #A5A5A5;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    cursor: pointer;
}

.no-notification-view {
    width: 100%;
    padding: 69px 0px;
}

.no-notification-view img {
    position: relative;
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
}

.no-notification-title {
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
    text-align: center;
    margin-top: 12px;
}


.no-notification-description {
    font-size: 12px;
    font-weight: 500;
    color: #A5A5A5;
    text-align: center;
    margin-top: 8px;
}










/* Profile drop styling */

.profile-drop-down-list-view {
    width: 100%;
    border-bottom: 1px solid #1D1D1D;
}

.profile-drop-down-container {
    width: 310px;
    z-index: 1;
    background-color: #141414;
    position: absolute;
    top: 90px;
    right: 50px;
}

.profile-info-view {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    border-bottom: 1px solid #1D1D1D;
    gap: 25px;
}

.profile-image-view {
    width: 15%;
}

.profile-image {
    width: 40px;
    height: 40px;
    border-radius: 100px;
}

.profile-user-info-view {
    width: 179px;
    width: 85%;
}

.profile-name {
    font-size: 14px;
    color: #FFFFFF;
    font-weight: 500;
    line-height: 24px;
}

.profile-email {
    font-size: 14px;
    color: #A5A5A5;
    font-weight: 400;

}

.profile-list-view {
    width: 100%;
    border-bottom: 1px solid #1D1D1D;
    cursor: pointer;

}

.profile-list-container {
    width: 100%;
    padding: 12px 21px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
}

.profile-route {
    color: #FFFFFF;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
}